import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BigNumber, constants } from 'ethers'

import { useWeb3React } from 'src/hooks/useWeb3React'
import useLPTokenContract from 'src/hooks/contracts/useLPTokenContract'
import useStakingContract from 'src/hooks/contracts/useStakingContract'
import { useLBAContract } from 'src/hooks/contracts/useLBAContract'
import { getSignature } from 'src/utils/lbaMigrationSignature'
import useEnergyConverterContract from 'src/hooks/contracts/useEnergyConverterContract'
import { formatTokenValue } from 'src/utils/formatTokenValue'
import { useEffect, useState } from 'react'
import { useGenomeMining } from 'src/hooks/useGenomeMining'
import useAvailableASTOEnergyBalance from 'src/hooks/useAvailableASTOEnergyBalance'
import { reportEvent } from 'src/utils/ga'

interface TransactionError {
  code?: number
  message?: string
}

export const useLBAMigration = () => {
  const { account } = useWeb3React()
  const stakingContract = useStakingContract()
  const lpTokenContract = useLPTokenContract()
  const energyConverterContract = useEnergyConverterContract()
  const lbaContract = useLBAContract()
  const queryClient = useQueryClient()

  const [currentStep, setCurrentStep] = useState(0)

  const {
    astoAuctionParticipationQuery,
    amountAstoEnergyGeneratedQuery,
    stakingHistoryAsto,
    stakingHistoryLp,
  } = useGenomeMining()

  const aeBalanceQuery = useAvailableASTOEnergyBalance()

  const isLBAEnergyFinishedQuery = useQuery(
    ['lba', 'is-lba-energy-finished'],
    async () => {
      return await energyConverterContract.isLBAEnergyFinished()
    },
  )

  const setApprovalToTrue = () => {
    queryClient.setQueryData([account, 'lba-migration', 'approval'], true)
  }

  const setMigrateToTrue = () => {
    queryClient.setQueryData([account, 'lba-migration', 'migrate'], true)
  }

  const signatureQuery = useQuery(
    [account, 'lba-migration', 'signature'],
    async () => {
      if (!account) return
      const data = await getSignature({ account, throwOnError: false })
      return data
    },
    { enabled: !!account, retry: false },
  )

  const approvalQuery = useQuery(
    [account, 'lba-migration', 'approval'],
    async () => {
      if (!account) return false
      const allowance = await lpTokenContract.allowance(
        account,
        stakingContract.address,
      )
      // assuming allowance that is non-zero is sufficient for now
      return !allowance.isZero()
    },
    { enabled: !!account },
  )

  const approveContractMutation = useMutation(
    async () => {
      const tx = await lpTokenContract.approve(
        stakingContract.address,
        constants.MaxUint256,
      )
      await tx.wait()
      return true
    },
    {
      onSuccess: () => {
        reportEvent('wallet_txn_approved', {
          contract: 'LPTokenContract',
          method: 'approve',
        })
        setApprovalToTrue()
      },
      onError: (e: TransactionError) => {
        if (e.code === 4001) {
          reportEvent('wallet_txn_rejected', {
            contract: 'LPTokenContract',
            method: 'approve',
          })
        }
      },
    },
  )

  // Step 2

  const claimableLPAmountQuery = useQuery(
    [account, 'lba', 'claimable-lp'],
    async () => {
      if (!account) return BigNumber.from(0)
      const claimableLPAmount = await lbaContract.claimableLPAmount(account)
      return claimableLPAmount
    },
    { enabled: !!account },
  )

  const claimMutation = useMutation(
    async () => {
      const tx = await lbaContract.claimLPToken()
      await tx.wait()
    },
    {
      onSuccess: () => {
        reportEvent('wallet_txn_approved', {
          contract: 'LPTokenContract',
          method: 'claimLPToken',
        })

        astoAuctionParticipationQuery.refetch()
        setCurrentStep(2)
      },
      onError: (e: TransactionError) => {
        if (e.code === 4001) {
          reportEvent('wallet_txn_rejected', {
            contract: 'LPTokenContract',
            method: 'claimLPToken',
          })
        }
      },
    },
  )

  // Step 3

  const migrateQuery = useQuery(
    [account, 'lba-migration', 'migrate'],
    async () => {
      if (!account) return false
      const hasMigrated = await stakingContract.lbaMigrated(account)
      return hasMigrated
    },
    {
      enabled: !!account,
    },
  )

  const migrateMutation = useMutation(
    async () => {
      if (!account) {
        throw new Error('No wallet detected')
      }

      const { amount, token } = (await getSignature({
        account,
        throwOnError: true,
      })) as { amount: string; token: string }

      const tx = await stakingContract.migrateAuctionLP(amount, token)
      await tx.wait()
      setMigrateToTrue()
    },
    {
      onSuccess: () => {
        reportEvent('wallet_txn_approved', {
          contract: 'StakingContract',
          method: 'migrateAuctionLP',
        })

        amountAstoEnergyGeneratedQuery.refetch()
        aeBalanceQuery.refetch()
        stakingHistoryAsto.refetch()
        stakingHistoryLp.refetch()
      },
      onError: (e: TransactionError) => {
        if (e.code === 4001) {
          reportEvent('wallet_txn_rejected', {
            contract: 'StakingContract',
            method: 'migrateAuctionLP',
          })
        }
      },
    },
  )

  const hasApproved = approvalQuery.isSuccess && approvalQuery.data
  const hasClaimableLP =
    claimableLPAmountQuery.isSuccess &&
    claimableLPAmountQuery.data &&
    !claimableLPAmountQuery.data.isZero()
  const hasClaimed = !!(signatureQuery.isSuccess && !!signatureQuery.data)
  const hasCompletedMigration = !!migrateQuery.data
  const isMigrating = hasClaimed && !hasCompletedMigration
  const canMigrate = (hasClaimableLP || hasClaimed) && !hasCompletedMigration
  const isLBAEnergyFinished = !!(
    isLBAEnergyFinishedQuery.isSuccess && isLBAEnergyFinishedQuery.data
  )

  useEffect(() => {
    // migration completed according to contract method
    if (hasCompletedMigration) {
      setCurrentStep(3)
      return
    }

    // detecting if on this step requires the signature
    if (isMigrating) {
      if (!hasApproved) {
        setCurrentStep(1)
        return
      }
      setCurrentStep(2)
      return
    }

    // if migration is not completed, check first if user has approved
    // if (!hasApproved) {
    //   setCurrentStep(1)
    //   return
    // }

    // not migrating(no signature from be), and can migrate(has claimable LP)
    if (canMigrate) {
      setCurrentStep(1)
      return
    }

    if (hasClaimableLP) {
      if (currentStep !== 1) {
        setCurrentStep(1)
      } else {
        setCurrentStep(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasApproved, hasClaimableLP, hasCompletedMigration, isMigrating])

  const aeToMigrateQuery = useQuery(
    [account, 'lba-migration', 'ae-to-migrate'],
    async () => {
      if (!account) return '0'
      const availableLBAEnergyBN =
        await energyConverterContract.getRemainingLBAEnergy(account)

      const availableLBAEnergy = formatTokenValue(availableLBAEnergyBN, 0, 36)
      return availableLBAEnergy
    },
  )

  return {
    approvalQuery,
    claimableLPAmountQuery,
    migrateQuery,
    currentStep,
    approveContractMutation,
    claimMutation,
    migrateMutation,
    isMigrating,
    canMigrate,
    hasMigrated: hasCompletedMigration,
    aeToMigrateQuery,
    signatureQuery,
    isLBAEnergyFinished,
    hasClaimableLP,
  }
}
