import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletConnectV2Connector } from '../connectors/WalletConnectV2Connector'

import { CHAIN_ID } from 'src/utils/web3'
import config from './config'

export enum ConnectorNames {
  Coinbase = 'Coinbase',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

const metamask = new InjectedConnector({
  supportedChainIds: [CHAIN_ID],
})

// the v1 version of walletconnect is deprecated
// const walletconnect = (rpcUrl: string) =>
//   new WalletConnectConnector({
//     qrcode: true,
//     rpc: { [CHAIN_ID]: rpcUrl },
//     pollingInterval: POLLING_INTERVAL,
//     bridge: 'https://bridge.walletconnect.org',
//   })

const REACT_APP_WALLET_CONNECT_PROJECT_ID =
  config.REACT_APP_WALLET_CONNECT_PROJECT_ID

const walletconnectV2 = (rpcUrl: string) =>
  new WalletConnectV2Connector({
    showQrModal: true,
    rpcMap: { [CHAIN_ID]: rpcUrl },
    projectId: REACT_APP_WALLET_CONNECT_PROJECT_ID,
    chains: [CHAIN_ID],
  })

const coinbase = (rpcUrl: string) =>
  new WalletLinkConnector({
    url: rpcUrl,
    appName: 'ASM Dashboard',
  })

export const connectors = (
  rpcUrl: string,
  name: ConnectorNames,
): AbstractConnector | null => {
  switch (name) {
    case ConnectorNames.MetaMask:
      return metamask
    case ConnectorNames.Coinbase:
      return coinbase(rpcUrl)
    case ConnectorNames.WalletConnect:
      return walletconnectV2(rpcUrl)
    default:
      return null
  }
}

const CONNECTOR_STORAGE_KEY = 'wallet-connector-name'

export const getConnectorName = () => {
  const connector = localStorage.getItem(CONNECTOR_STORAGE_KEY)

  return connector ? (connector as ConnectorNames) : null
}

export const setConnectorToStorage = (connectorName: ConnectorNames) => {
  localStorage.setItem(CONNECTOR_STORAGE_KEY, connectorName)
}

export const removeConnectorFromStorage = () => {
  localStorage.removeItem(CONNECTOR_STORAGE_KEY)
}
