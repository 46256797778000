import type WalletConnectProvider from '@walletconnect/ethereum-provider'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { ConnectorUpdate } from '@web3-react/types'
import { getBestUrlMap, getChainsWithDefault } from './utils'

export const URI_AVAILABLE = 'URI_AVAILABLE'
const DEFAULT_TIMEOUT = 5000

/**
 * Options to configure the WalletConnect provider.
 * For the full list of options, see {@link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization WalletConnect documentation}.
 */
export type WalletConnectV2Options = Omit<
  Parameters<typeof WalletConnectProvider.init>[0],
  'rpcMap'
> & {
  /**
   * Map of chainIds to rpc url(s). If multiple urls are provided, the first one that responds
   * within a given timeout will be used. Note that multiple urls are not supported by WalletConnect by default.
   * That's why we extend its options with our own `rpcMap` (@see getBestUrlMap).
   */
  rpcMap?: { [chainId: number]: string | string[] }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super()
    this.name = this.constructor.name
    this.message = 'The user rejected the request.'
  }
}

export interface WalletConnectV2ConstructorArgs extends WalletConnectV2Options {
  supportedChainIds?: number[]
  /** The chainId to connect to in activate if one is not provided. */
  defaultChainId?: number
  /**
   * @param timeout - Timeout, in milliseconds, after which to treat network calls to urls as failed when selecting
   * online urls.
   */
  timeout?: number
}

function getSupportedChains({
  supportedChainIds,
  rpcMap,
}: WalletConnectV2ConstructorArgs): number[] | undefined {
  if (supportedChainIds) {
    return supportedChainIds
  }

  return rpcMap ? Object.keys(rpcMap).map(k => Number(k)) : undefined
}

export class WalletConnectV2Connector extends AbstractConnector {
  public provider?: WalletConnectProvider
  private readonly config: WalletConnectV2ConstructorArgs
  private readonly rpcMap?: Record<number, string | string[]>
  private readonly chains: number[]
  private readonly defaultChainId?: number
  private readonly timeout: number

  private eagerConnection?: Promise<WalletConnectProvider>

  constructor(config: WalletConnectV2ConstructorArgs) {
    super({ supportedChainIds: getSupportedChains(config) })

    const { rpcMap, chains, defaultChainId, timeout = DEFAULT_TIMEOUT } = config

    this.config = config
    this.chains = chains
    this.defaultChainId = defaultChainId
    this.rpcMap = rpcMap
    this.timeout = timeout

    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleDisconnect = this.handleDisconnect.bind(this)
  }

  private handleChainChanged(chainId: number | string): void {
    this.emitUpdate({ chainId })
  }

  private handleAccountsChanged(accounts: string[]): void {
    this.emitUpdate({ account: accounts[0] })
  }

  private handleDisconnect(): void {
    // we have to do this because of a @walletconnect/web3-provider bug
    if (this.provider) {
      this.provider.removeListener('chainChanged', this.handleChainChanged)
      this.provider.removeListener(
        'accountsChanged',
        this.handleAccountsChanged,
      )
      this.provider = undefined
    }
    this.emitDeactivate()
  }

  private URIListener = (uri: string): void => {
    this.emit(URI_AVAILABLE, uri)
  }

  private isomorphicInitialize(
    desiredChainId: number | undefined = this.defaultChainId,
  ): Promise<WalletConnectProvider> {
    if (this.eagerConnection) return this.eagerConnection

    const rpcMap = this.rpcMap
      ? getBestUrlMap(this.rpcMap, this.timeout)
      : undefined
    const chains = desiredChainId
      ? getChainsWithDefault(this.chains, desiredChainId)
      : this.chains

    return (this.eagerConnection = import(
      '@walletconnect/ethereum-provider'
    ).then(async ethProviderModule => {
      const provider = (this.provider = await ethProviderModule.default.init({
        ...this.config,
        chains,
        rpcMap: await rpcMap,
      }))

      return provider
        .on('disconnect', this.handleDisconnect)
        .on('chainChanged', this.handleChainChanged)
        .on('accountsChanged', this.handleAccountsChanged)
        .on('display_uri', this.URIListener)
    }))
  }

  /** {@inheritdoc Connector.connectEagerly} */
  public async connectEagerly(): Promise<void> {
    try {
      const provider = await this.isomorphicInitialize()
      // WalletConnect automatically persists and restores active sessions
      if (!provider.session) {
        throw new Error('No active session found. Connect your wallet first.')
      }
      this.emitUpdate({
        account: provider.accounts[0],
        chainId: provider.chainId,
      })
    } catch (error) {
      await this.deactivate()
      throw error
    }
  }

  public async activate(desiredChainId?: number): Promise<ConnectorUpdate> {
    const provider = await this.isomorphicInitialize(desiredChainId)
    const account = provider.accounts[0]
    if (provider.session) {
      if (!desiredChainId || desiredChainId === provider.chainId)
        return {
          provider,
          account,
        }
      // WalletConnect exposes connected accounts, not chains: `eip155:${chainId}:${address}`
      const isConnectedToDesiredChain =
        provider.session.namespaces.eip155.accounts.some(account =>
          account.startsWith(`eip155:${desiredChainId}:`),
        )
      if (!isConnectedToDesiredChain) {
        if (this.config.optionalChains?.includes(desiredChainId)) {
          throw new Error(
            `Cannot activate an optional chain (${desiredChainId}), as the wallet is not connected to it.\n\tYou should handle this error in application code, as there is no guarantee that a wallet is connected to a chain configured in "optionalChains".`,
          )
        }
        throw new Error(
          `Unknown chain (${desiredChainId}). Make sure to include any chains you might connect to in the "chains" or "optionalChains" parameters when initializing WalletConnect.`,
        )
      }
      await provider.request<void>({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${desiredChainId.toString(16)}` }],
      })

      return {
        provider,
        account,
      }
    }

    try {
      await provider.enable()
      this.emitUpdate({
        chainId: provider.chainId,
        account: provider.accounts[0],
      })
    } catch (error) {
      await this.deactivate()
      throw error
    }

    return { provider, account }
  }

  public async getProvider(): Promise<any> {
    return this.provider
  }

  public async getChainId(): Promise<number | string> {
    return Promise.resolve(this.provider!.chainId)
  }

  public async getAccount(): Promise<null | string> {
    return Promise.resolve(this.provider!.accounts).then(
      (accounts: string[]): string => accounts[0],
    )
  }

  public async deactivate(): Promise<void> {
    await this.provider
      ?.removeListener('disconnect', this.handleDisconnect)
      .removeListener('chainChanged', this.handleChainChanged)
      .removeListener('accountsChanged', this.handleAccountsChanged)
      .disconnect()

    this.provider = undefined
    this.eagerConnection = undefined
  }

  public async close() {
    this.emitDeactivate()
  }
}
